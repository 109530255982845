<template>
    <div class='page404-container'>
    404
    </div>
</template>
<script>
export default {
    name:'page404'
}
</script>
<style>
.page404-container{
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background:#fff;
    font-size:16vw;
}
</style>